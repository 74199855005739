import http from '@/services/http-client';
import routes from '@/../config/env';

const { replay } = routes;

export const getOriginalEventId = async ({ eventId = '' }) => {
  try {
    const response = await http({
      method: 'post',
      url: `${replay}/events/get-replay-metadata`,
      data: {
        eventIds: [eventId],
      },
    });
    const [replayEvent] = response.data ?? [];
    return replayEvent?.originalEventId ?? eventId;
  } catch (error) {
    console.error(error);
    return eventId;
  }
};

export const isReplayable = async ({ eventId = '', eventType = '' }) => {
  try {
    let originalEventId = eventId;
    if (eventType === 'REPLAY') {
      originalEventId = await getOriginalEventId({ eventId });
    }

    const response = await http({
      method: 'post',
      url: `${replay}/events/check-if-exist`,
      data: {
        eventIds: [originalEventId],
      },
    });
    const eventsMap = response.data ?? [];
    return !!eventsMap[originalEventId];
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const scheduleReplay = async ({ eventId = '', eventType = '', offsets = [] }) => {
  try {
    let originalEventId = eventId;
    if (eventType === 'REPLAY') {
      originalEventId = await getOriginalEventId({ eventId });
    }

    const response = await http({
      method: 'post',
      url: `${replay}/events/schedule-per-multiple-offsets`,
      data: {
        eventIds: [originalEventId],
        eventStartOffsetMinutes: offsets,
        speedRate: 1,
      },
    });
    const [replayEvent] = response.data ?? [];
    return !!replayEvent?.newEventId;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const fastForwardReplay = async ({ eventId = '' }) => {
  try {
    const response = await http({
      method: 'post',
      url: `${replay}/events/stop-by-id`,
      data: {
        eventId,
      },
    });
    return response.data ?? '';
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const recordingInfo = async ({ eventId = '', eventType = '' }) => {
  try {
    let originalEventId = eventId;
    if (eventType === 'REPLAY') {
      originalEventId = await getOriginalEventId({ eventId });
    }

    const response = await http({
      method: 'post',
      url: `${replay}/events/recording-info`,
      data: {
        eventIds: [originalEventId],
      },
    });
    return response.data[originalEventId] ?? null;
  } catch (error) {
    console.error(error);
    return false;
  }
};
